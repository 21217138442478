<template>
  <a-drawer width="1000" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>绑定用户</b>
    </a-divider>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <template>
              <a-col :md="8" :sm="24">
                <a-form-item label="昵称" prop="nickname">
                  <a-input v-model="queryParam.nickname" placeholder="请输入昵称" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="手机号" prop="mobile">
                  <a-input v-model="queryParam.mobile" placeholder="请输入手机号" allow-clear />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="8" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-right: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        size="small"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false">
        <span slot="status" slot-scope="text, record">
          <a-badge color="red" text="禁用" v-if="record.status == 0"/>
          <a-badge color="cyan" text="启用" v-if="record.status == 1"/>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize" />
    </a-card>
    <div style="width: 100%;display: flex;justify-content: flex-end;">
      <a-button style="margin-right: 20px;" type="primary" :loading="submitLoading" @click="submitForm" :disabled="multiple">
        确认绑定
      </a-button>
      <a-button type="dashed" @click="cancel">
        取消
      </a-button>
    </div>
  </a-drawer>
</template>

<script>
import { pageUser } from '@/api/user/user'
import { getDevice, bindDevice } from '@/api/valve/device'

export default {
  name: 'User',
  props: {
  },
  data() {
    return {
      did: null,
      open: false,
      submitLoading: false,

      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        nickname: '',
        mobile: '',
        pageNum: 1,
        pageSize: 10
      },
      // 非多个禁用
      multiple: true,
      selectedRowKeys: [],
      selectedRows: [],
      list: [],
      columns: [
        {
          title: '手机号',
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '昵称',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用户等级',
          dataIndex: 'levelStr',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '生日',
          dataIndex: 'birthday',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用户状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '注册时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center',
          width: 150
        }
      ]
    }
  },
  filters: {
  },
  created() {
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    /** 查询用户信息列表 */
    bdList(id) {
      this.did = id
      this.open = true
      this.loading = true
      this.queryParam = {
        nickname: '',
        mobile: '',
        pageNum: 1,
        pageSize: 10
      }
      this.selectedRowKeys = []
      this.selectedRows = []
      this.list = []
      if (id) {
        getDevice({ id }).then(res => {
          const ulist = res.data.userDevResultList
          if (ulist && ulist.length > 0) {
            this.selectedRowKeys = ulist.map(item => item.id)
            this.selectedRows = ulist
            this.multiple = !ulist.length
          }
        })
      }
      pageUser(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.list.forEach(element => {
          if (element.userLevel === 0) {
            element.levelStr = 'S'
          } else if (element.userLevel === 1) {
            element.levelStr = 'A'
          } else if (element.userLevel === 2) {
            element.levelStr = 'B'
          } else if (element.userLevel === 3) {
            element.levelStr = 'C'
          }
        })
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.bdList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        nickname: '',
        mobile: '',
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.bdList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.bdList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.formType = 2
      const id = row ? row.id : ids
      getDevice({ 'id': id }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
        this.fileList = [{
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: response.data.imgUrl
        }]
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      // this.submitLoading = true
      bindDevice({
        id: this.did,
        userIds: this.selectedRowKeys
      }).then(response => {
        this.$message.success(
          '绑定成功',
          3
        )
        this.open = false
        this.$emit('ok')
      }).finally(() => {
        this.submitLoading = false
      })
    }
  }
}
</script>
