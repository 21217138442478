import request from '@/utils/request'

// 查询用户信息列表
export function listUser(query) {
  return request({
    url: '/user/user/list',
    method: 'get',
    params: query
  })
}

// 查询用户信息分页
export function pageUser(query) {
  return request({
    url: '/user/user/page',
    method: 'get',
    params: query
  })
}

// 查询用户信息详细
export function getUser(data) {
  return request({
    url: '/user/user/detail',
    method: 'get',
    params: data
  })
}

// 新增用户信息
export function addUser(data) {
  return request({
    url: '/user/user/add',
    method: 'post',
    data: data
  })
}

// 修改用户信息
export function updateUser(data) {
  return request({
    url: '/user/user/edit',
    method: 'post',
    data: data
  })
}

// 删除用户信息
export function delUser(data) {
  return request({
    url: '/user/user/delete',
    method: 'post',
    data: data
  })
}

// 绑定设备
export function bindUser(data) {
  return request({
    url: '/user/user/bind',
    method: 'post',
    data: data
  })
}

// 根据身份证修改用户状态
export function editUserByIdcard(data) {
  return request({
    url: '/user/user/edit',
    method: 'post',
    data: data
  })
}

// 修改用户状态
export function editUserById(data) {
  return request({
    url: '/user/user/edit/id',
    method: 'post',
    data: data
  })
}

// 重置密码
export function editUserResetPassword(data) {
  return request({
    url: '/user/user/reset-password',
    method: 'post',
    data: data
  })
}
